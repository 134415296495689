import { useEffect, useState } from "react";
import "./styles.css";


const getStateFromLocalStorage = () => {
  const storage = localStorage.getItem('caloriesStorage');
  if (storage) {
    console.log(storage);
    return JSON.parse(storage).items;
  }
  return [];
}
const storeStateInLocalStorage = items => {
  localStorage.setItem('caloriesStorage', JSON.stringify({ items }));
  console.log("storeStateInLocalStorage. localStorage:", localStorage);
}

const LambdaDemo = () => {
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState("Default Message");

  const callLambdaFunction = (api) => {
    setLoading(true);
    fetch("/.netlify/functions/" + api)
    .then((response) => response.json())
    .then((json) => { setLoading(false); setMessage(json.msg) })
  }

  return (
    <div>
      {
        loading ?
        <div>Loading...</div>
        :
        <div>{message}</div>
      }
      <button onClick={() => (callLambdaFunction("greetings"))} className="btn">Load a greeting</button>
      <button onClick={() => (callLambdaFunction("jokes"))}className="btn">Load a funny joke</button>
    </div>
  );
};

export default LambdaDemo;
import { useEffect, useState } from "react";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import "./styles.css";
import LambdaDemo from "./LambdaDemo";


const client = new ApolloClient({
  uri: "/.netlify/functions/graphql",
  // uri: "http://localhost:9000/graphql"
});

console.log(client);

const getHello = gql`
  query {
    hello
  }
`;

const LambdaDemoApollo = () => (
  <ApolloProvider client={client}>
    <Query
      query={getHello}
    >
      {
        ({ data }) =>
        {
          console.log(data);
          return (
            <div>
              A greeting from the server: {data?.hello}
            </div>
          )
        }
      }
    </Query>
  </ApolloProvider>
);

const getStateFromLocalStorage = () => {
  const storage = localStorage.getItem('caloriesStorage');
  if (storage) {
    console.log(storage);
    return JSON.parse(storage).items;
  }
  return [];
}
const storeStateInLocalStorage = items => {
  localStorage.setItem('caloriesStorage', JSON.stringify({ items }));
  console.log("storeStateInLocalStorage. localStorage:", localStorage);
}

export default function App() {
  const [calValue, setCalValue] = useState("");
  const [kjValue, setKjValue] = useState("");
  const [convertedCalValue, setConvertedCalValue] = useState(0);
  const [convertedKjValue, setConvertedKjValue] = useState(0);
  const [total, setTotal] = useState(0);
  // const [remaining, setRemaining] = useState({remaining: 1500, over: 0});
  const [remaining, setRemaining] = useState(1000);
  const [items, setItems] = useState(getStateFromLocalStorage());
  const [timestamp, setTimestamp] = useState("");
  const nanOr0 = (n) => (isNaN(n) ? 0 : parseFloat(n));
  const convertedTimestamp = (timestamp) =>
    new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }).format(timestamp);

  useEffect(() => {
    setConvertedKjValue(Math.round(kjValue / 4.2));
  }, [kjValue]);

  useEffect(() => {
    setConvertedCalValue(Math.round(calValue * 4.2));
  }, [calValue]);

  useEffect(() => {
    //setTimestamp(() => convertedTimestamp(Date.now()));
    setTotal(items.reduce((a, b) => nanOr0(a) + nanOr0(b), 0));
    storeStateInLocalStorage(items);
  }, [items]);

  useEffect(() => {
    setRemaining(nanOr0(remaining) - total);
  }, [total]);

  const addCalItem = () => {
    setItems((items) => [...items, calValue]);
    setCalValue("");
  };
  const addKjItem = () => {
    setItems((items) => [...items, convertedKjValue]);
    setKjValue("");
  };
  const clearLog = () => {
    setItems([]);
  }
  const saveLog = () => {
    alert("Save functionality coming soon")
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:p-6 gap-y-1 md:gap-x-2 gap-y-0">
      <div className="bg-gray-50 p-4 md:p-6">
        <h3>Conversions</h3>
        <div className="mb-4">
          <h4 className="custom:text-lx6">kjs</h4>
          <div className="flex items-start">
            <input
              type="number"
              onChange={(e) => setKjValue(e.target.value)}
              value={kjValue}
            />
            <button className="btn" onClick={() => addKjItem()}>+</button>
          </div>
          <div className="text-xs mt-2">
            {convertedKjValue} calories
          </div>
        </div>
        <div>
          <h4>calories</h4>
          <div className="flex items-start">
            <input
              type="number"
              onChange={(e) => setCalValue(e.target.value)}
              value={calValue}
            />
            <button className="btn" onClick={() => addCalItem()}>+</button>
          </div>
          <div className="text-xs mt-2">
            {convertedCalValue} kjs
          </div>
        </div>
      </div>
      <div className="bg-gray-50 p-4 md:p-6">
        <div className="grid grid-cols-2 gap-4 py-2">
          <div>
            <h3>Log</h3>
          </div>
          {
            items.length ?
              <div className="grid justify-items-end">
                <button className="btn" onClick={() => (clearLog())}>Clear log</button>
              </div> : null
          }
        </div>
        {
          items.length ?
            <ul className="bg-white">
              {
                items.map((item) => (
                  <li className="p-2 border-b border-gray-200">
                    {item}
                  </li>
                ))
              }
            </ul>
            :
            <ul className="bg-white">
              <li className="p-2 border-b border-gray-200">
                No items yet
              </li>
            </ul>
        }
        <div className="grid grid-cols-2 gap-4 pt-4">
          <div className="">
            <div className="">
              <b>Total:</b> {total} calories
            </div>
            <div className="mt-2">
              <b>Remaining:</b> {remaining} calories
              {/* {
                    remaining.over &&
                    <><b>Remaining:</b> {remaining.over} calories<br /></>
                  } */}
            </div>
          </div>
          <div className="grid justify-items-end">
            <button className="btn" onClick={() => (saveLog())}>Save</button>
          </div>
        </div>


      </div>
     

      <div className="bg-gray-50 p-4 md:p-6"><LambdaDemo /></div>
      <div className="bg-gray-50 p-4 md:p-6"><LambdaDemoApollo /></div>
    </div>
  );
}
